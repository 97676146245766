<form [formGroup]="form" (ngSubmit)="login()">
  <div class="container" style="margin-top: 80px; padding: 30px">
    <h1 class="heading">
      <i class="fas fa-check-circle icon"></i>
      Admin Login
    </h1>
    <hr class="line" />
    <div class="form-group">
      <label for="oldPassword">Email</label>
      <input
        type="text"
        id="email"
        name="email"
        class="form-control"
        placeholder="Enter your Email"
        formControlName="email"
      />
      <div
        *ngIf="
          form.get('email').hasError('required') &&
          (form.get('email').dirty || form.get('email').touched)
        "
        style="color: red"
      >
        Email is required.
      </div>
      <div
        *ngIf="
          form.get('email').hasError('email') &&
          (form.get('email').dirty || form.get('email').touched)
        "
        style="color: red"
      >
        Invalid Email Format
      </div>
    </div>
    <div class="form-group mt-3">
      <label for="newPassword">Password</label>
      <input
        type="password"
        id="password"
        name="password"
        class="form-control"
        placeholder="Enter your  password"
        formControlName="password"
      />
    </div>
    <div
      *ngIf="
        form.get('password').hasError('required') &&
        (form.get('password').dirty || form.get('password').touched)
      "
      style="color: red; text-align: left"
    >
      Password is required.
    </div>
    <button
      type="submit"
      [disabled]="!form.valid"
      class="btn btn-primary rounded-corner mt-3"
    >
      Login
    </button>
  </div>
</form>
