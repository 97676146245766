<app-admin-nav></app-admin-nav>
<div *ngIf="isLoading" class="outer-spinner">
  <div class="loading-spinner"></div>
</div>
<div class="outside container col-sm-8 mt-4" *ngIf="!isLoading">
  <label for="floatingInput mb-3">User View Text</label>
  <div class="form mb-3 mt-1">
    <input
      type="email"
      name="admin_text"
      class="form-control"
      [(ngModel)]="admin_text"
      placeholder="some text"
    />
  </div>
  <div class="d-grid gap-2 mb-3 d-md-flex justify-content-md-end">
    <button class="btn btn-primary me-md-2" (click)="adminText()" type="button">
      Save
    </button>
  </div>
  <h2 class="mb-3">Statistics of Site:</h2>
  <div class="row mb-3">
    <div class="col-sm-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" style="color: rgb(35, 149, 243)">
            Users Registered
          </h5>
          <p class="card-text">{{ UsersUsage }}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" style="color: rgb(35, 149, 243)">
            Card Created
          </h5>
          <p class="card-text">{{ CardUsage }}</p>
        </div>
      </div>
    </div>
  </div>
  <p>Packages usage Information:</p>
  <div class="row">
    <div class="col-sm-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" style="color: rgb(35, 149, 243)">$0</h5>
          <p class="mt-3">Using by users: 3</p>
          <p class="">Cards limit: 3</p>
          <p class="">Duration:</p>
          <p class="">Free for testing</p>
        </div>
      </div>
    </div>
  </div>
</div>
