<app-nav></app-nav>

<div *ngIf="isLoading" class="outer-spinner">
  <div class="loading-spinner"></div>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-4">
      <ul class="list-group">
        <li
          *ngFor="let user of chatUsers"
          (click)="openChat(user.user.id)"
          class="list-group-item cursor-pointer user-list"
        >
          <span class="user-avatar">
            <i class="fas fa-user-circle"></i>
          </span>
          {{ user.user.firstname + " " + user.user.lastname }}
        </li>
      </ul>
    </div>
    <div class="col-md-8 outsideMsg" *ngIf="chatDisplay">
      <div *ngIf="loadMessage" class="outer-spinner">
        <div class="loading-spinner"></div>
      </div>
      <div class="chat-messages" id="chat-messages" *ngIf="!loadMessage">
        <div
          *ngFor="let message of chatMessages"
          class="message-container"
          [ngClass]="{
            'message-sender': message.user_id != id,
            'message-receiver': message.user_id == id
          }"
        >
          <div class="message-bubble">
            {{ message.chat }}
            <div class="message-time">
              {{ message.created_at | date : "shortTime" }}
            </div>
          </div>
        </div>
      </div>
      <div class="message-input">
        <input
          [(ngModel)]="newMessage"
          placeholder="Type your message..."
          class="form-control"
        />

        <button (click)="sendMessage()" class="btn btn-primary">Send</button>
        <!-- <button (click)="sendMessage()" class="btn btn-primary">Send</button> -->
      </div>
    </div>
  </div>
</div>
