<div class="row" style="width: 100%" *ngIf="myContacts.length > 0">
  <div *ngFor="let contact of myContacts" class="col-12 col-md-4">
    <div class="card mb-3" style="min-width: 200px">
      <div class="card-body d-flex align-items-center">
        <div class="avatar mr-3">
          <!-- Add your avatar image or icon here -->
          <img
            src="assets/images/avatar.png"
            style="height: 40px; width: 40px"
            alt="{{ contact.user.firstname }}"
            class="avatar-image"
          />
        </div>
        <span style="padding-left: 5px"
          >{{ contact.user.firstname }} {{ " " + contact.user.lastname }}</span
        >
      </div>
      <div class="card-footer">
        <div class="dropdown">
          <p
            class="status"
            (click)="sendMessage(contact.id)"
            style="color: #8dc63f"
            *ngIf="contact.status === 'Accepted'"
          >
            Send Message
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
