<div *ngIf="isLoading" class="outer-spinner">
  <div class="loading-spinner"></div>
</div>

<div class="outside">
  <div class="table-responsive table-container table-sm mt-4">
    <table class="table">
      <thead>
        <tr>
          <th class="col-1">Date</th>
          <th class="col-1">Email</th>
          <th class="col-2">Total Package Amount</th>
          <th class="col-1 col-md-1">Bonus Amount</th>
          <th class="col-1">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let amt of amountData; let i = index">
          <td>{{ amt.PurchasedDate }}</td>
          <td>{{ amt.whoPurchased }}</td>
          <td>{{ amt.totalPackageAmount }}</td>
          <td>{{ amt.bonusAmount }}</td>
          <td>{{ amt.status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
