<app-admin-nav></app-admin-nav>
<div *ngIf="isLoading" class="outer-spinner">
  <div class="loading-spinner"></div>
</div>
<div class="container" style="margin-top: 80px; padding: 30px">
  <h1 class="heading">
    <i class="fas fa-check-circle icon"></i>
    Update Support Agent
  </h1>
  <hr class="line" />
  <div class="form-group">
    <input
      type="text"
      class="form-control"
      placeholder="Name"
      [(ngModel)]="name"
    />
  </div>
  <div class="form-group mt-3">
    <input
      type="text"
      class="form-control"
      placeholder="Email"
      [(ngModel)]="email"
    />
  </div>

  <button class="btn btn-primary rounded-corner mt-3" (click)="updateAgent()">
    Update
  </button>
</div>
