<app-nav></app-nav>
<div class="container">
  <div class="row">
    <nav class="col-md-3 col-lg-3 d-md-block bg-light sidebar">
      <div class="position-sticky">
        <!-- Your navigation menu (app-profile-nav) goes here -->
        <app-profile-nav></app-profile-nav>
      </div>
    </nav>

    <main class="col-md-9 ms-sm-auto col-lg-9 px-md-4">
      <!-- The content of the currently active profile section will be displayed here -->
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
