<app-admin-nav></app-admin-nav>
<div class="container" style="margin-top: 80px; padding: 30px">
  <h1 class="heading">
    <i class="fas fa-check-circle icon"></i>
    Change Password
  </h1>
  <hr class="line" />
  <div class="form-group">
    <label for="oldPassword">Old Password</label>
    <input
      type="password"
      id="oldPassword"
      class="form-control"
      placeholder="Enter your old password"
    />
  </div>
  <div class="form-group mt-3">
    <label for="newPassword">New Password</label>
    <input
      type="password"
      id="newPassword"
      class="form-control"
      placeholder="Enter your new password"
    />
  </div>
  <button
    class="btn btn-primary rounded-corner mt-3"
    (click)="updatePassword()"
  >
    Update Password
  </button>
</div>
