<div *ngIf="isLoading" class="outer-spinner">
  <div class="loading-spinner"></div>
</div>

<div
  class="container"
  *ngIf="!isLoading"
  style="padding: 30px; margin-bottom: 60px"
>
  <h1 class="heading">
    <i class="fas fa-check-circle icon"></i>
    Edit Basic Information
  </h1>

  <form (submit)="saveChanges()" style="margin-top: 50px">
    <ng-container *ngFor="let key of getKeys(user); let i = index">
      <!-- Open a new row for the first item or every second item -->
      <div class="row" *ngIf="i % 2 === 0">
        <div class="col-md-6">
          <div class="form-group">
            <label [for]="key">{{ formatLabel(key) }}</label>
            <input
              type="text"
              [id]="key"
              [(ngModel)]="user[key]"
              [name]="key"
              class="form-control"
            />
          </div>
        </div>
        <!-- Check if there's a next item, and if so, create the second column -->
        <div class="col-md-6" *ngIf="i + 1 < getKeys(user).length">
          <div class="form-group">
            <label [for]="getKeys(user)[i + 1]">{{
              formatLabel(getKeys(user)[i + 1])
            }}</label>
            <input
              type="text"
              [id]="getKeys(user)[i + 1]"
              [(ngModel)]="user[getKeys(user)[i + 1]]"
              [name]="getKeys(user)[i + 1]"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Additional fields for gender and DOB if they are null -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="gender">Gender</label>
          <input
            type="text"
            id="gender"
            [(ngModel)]="gender"
            name="gender"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="DOB">Date of Birth</label>
          <input
            type="date"
            id="DOB"
            [(ngModel)]="DOB"
            name="DOB"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 50px">
      <div class="col-md-12">
        <button type="submit" class="btn btn-primary btn-rounded">
          Save Changes
        </button>
      </div>
    </div>
  </form>
</div>
